import ApiService from "@/core/services/api.service";
import Excel from "@/assets/js/pages/vegetable/excel.js";

var SupplierCustomerReport = {
    list: function(searchData) {
        return ApiService.post("/supplier-customer-report/list", searchData);
    },
    exportExcel: function(params) {
        Excel.exportExcel(
            "/supplier-customer-report/downLoadExcel", 
            params, 
            "SupplierDetailsReport.xls");
    }
}

export default SupplierCustomerReport;